import React, { useState, useEffect } from "react";
import MapInputComp from "../../components/MapInputComp";
import ButtonComp from "../../components/ButtonComp";
import { useLicenseStore } from "../../store/licenseStore";
import { useUserStore } from "../../store/userStore";
import axios from "axios";

const MappingTab = (brand_id = "") => {
  const { user } = useUserStore();
  const { selectedLicense } = useLicenseStore();
  const [dealValue, setDealValue] = useState("");

  interface HubspotFields {
    contacts: { value: string; label: string }[];
    companies: { value: string; label: string }[];
    deals: { value: string; label: string }[];
  }

  const [hubspotFields, setHubspotFields] = useState<HubspotFields>({
    contacts: [],
    companies: [],
    deals: [],
  });

  // Fetch HubSpot properties based on the object (contacts, companies, deals)
  const fetchHubSpotFieldsFromBackend = async () => {
    try {
      if (!user || !user.uid) {
        console.error("User or selected license not found");
        return;
      }
      const response = await axios.post(
        "https://us-central1-mjsync-b7cc9.cloudfunctions.net/getHubSpotFields",
        {
          data: {
            userId: user.uid,
            licenseId: selectedLicense?.id,
          },
        },
      );
      setHubspotFields(response.data);
    } catch (error) {
      console.error("Error fetching HubSpot fields from backend:", error);
    }
  };

  useEffect(() => {
    if (selectedLicense?.hubspot_access_token) {
      fetchHubSpotFieldsFromBackend(); // Fetch HubSpot fields when the component mounts
    }
  }, [selectedLicense]);

  const handleMapChange = (id: string, left: string, right: string) => {
    console.log(id, left, right);
    if (id === "rightDeal") {
      setDealValue(right);
    }
  };

  const data = [
    {
      name: "Contact",
      leaflink_fields: [
        { value: "first_name", label: "First Name" },
        { value: "last_name", label: "Last Name" },
        { value: "email", label: "Email" },
      ],
      hubspot_fields: hubspotFields.contacts.length
        ? hubspotFields.contacts
        : [{ value: "", label: "Loading..." }], // Ensure default value
    },
    {
      name: "Company",
      leaflink_fields: [
        { value: "name", label: "Company Name" },
        { value: "license_number", label: "License Number" },
      ],
      hubspot_fields: hubspotFields.companies.length
        ? hubspotFields.companies
        : [{ value: "", label: "Loading..." }], // Ensure default value
    },
    {
      name: "Deal",
      leaflink_fields: [{ value: "", label: "" }],
      hubspot_fields: hubspotFields.deals.length
        ? hubspotFields.deals
        : [{ value: "", label: "Loading..." }], // Ensure default value
    },
  ];

  const isDealSelected = dealValue !== "" && dealValue !== "-1";

  return (
    <div className="text-white">
      <div className="flex justify-between items-center mb-1">
        <h3 className="text-lg font-semibold">Field Mapping</h3>
        <ButtonComp variant="secondary" onClick={fetchHubSpotFieldsFromBackend}>
          Re-Sync HubSpot Fields
        </ButtonComp>
      </div>
      <p className="mt-2 text-gray-400">
        Map Leaflink fields to corresponding HubSpot fields. Customize how data
        is transferred between the two platforms.
      </p>

      <div className="mt-6">
        <div className="mt-2">
          {data.map((item, index) => (
            <React.Fragment key={index}>
              {!isDealSelected && item.name === "Order" ? (
                <p className="mt-4 mb-4 text-sm text-red-500">
                  Below field mapping is disabled until a Deal in Hubspot is
                  selected.
                </p>
              ) : null}
              <h3 className="text-lg font-semibold mt-4">{item.name}</h3>

              {item.leaflink_fields.map((field, index2) => (
                <div
                  key={index2}
                  className={`mt-2 ${
                    !isDealSelected &&
                    item.name !== "Deal" &&
                    item.name !== "Contact" &&
                    item.name !== "Company"
                      ? "opacity-50 cursor-not-allowed"
                      : "opacity-100"
                  }`}
                >
                  {field && item.hubspot_fields && (
                    <MapInputComp
                      key={index2}
                      id={item.name}
                      leftOptions={[field]}
                      rightOptions={item.hubspot_fields}
                      onChange={handleMapChange}
                      disabled={
                        !isDealSelected &&
                        item.name !== "Deal" &&
                        item.name !== "Contact" &&
                        item.name !== "Company"
                      }
                    />
                  )}
                </div>
              ))}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MappingTab;
