import React from "react";
import { Loader2 } from "lucide-react";
import SpinnerType from "../types/spinner";

const Spinner: React.FC<SpinnerType> = ({
  size = 24,
  color = "text-blue-500",
}) => (
  <div className="absolute inset-0 flex items-center justify-center bg-[#373839] bg-opacity-75 z-40">
    <Loader2 className={`animate-spin ${color}`} size={size} />
  </div>
);

export default Spinner;
