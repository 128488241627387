const mjSyncFeatures = [
  { icon: "🚀", title: "Increased Productivity" },
  { icon: "💰", title: "Cost-Effective" },
  { icon: "🖥️", title: "User-Friendly Interface" },
  { icon: "🔗", title: "Seamless Integration" },
  { icon: "🛠️", title: "24/7 Support" },
  { icon: "🔒", title: "Enhanced Security" },
];

const leaflinkHubspotFeatures = [
  { icon: "🚀", text: "Instant Sync" },
  { icon: "🔒", text: "Secure Data Transfer" },
  { icon: "📊", text: "Advanced Analytics" },
  { icon: "⏱️", text: "Time-Saving Automation" },
  { icon: "🔄", text: "Bi-directional Sync" },
  { icon: "📱", text: "Mobile Accessibility" },
];

export { mjSyncFeatures, leaflinkHubspotFeatures };
