import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import { getAuth, GoogleAuthProvider } from "firebase/auth";

import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyAYt6NTq4C7OmV7BZnb_McO-UeYgh92XoM",
  authDomain: "mjsync-b7cc9.firebaseapp.com",
  projectId: "mjsync-b7cc9",
  storageBucket: "mjsync-b7cc9.appspot.com",
  messagingSenderId: "103730771008",
  appId: "1:103730771008:web:be88b6b7eb5a2d5409dba5",
  measurementId: "G-BRDCNQRXN6",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const firestore = getFirestore(app);
const functions = getFunctions(app);

const auth = getAuth();
const googleProvider = new GoogleAuthProvider();

export { app, analytics, firestore, auth, googleProvider, functions };
