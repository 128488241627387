import {
  collection,
  doc,
  setDoc,
  addDoc,
  getDocs,
  getDoc,
  serverTimestamp,
  updateDoc,
  DocumentReference,
  DocumentData,
  CollectionReference,
} from "firebase/firestore";
import License from "../modals/License";
import { firestore } from "../services/firebase";

/**
 * Get all licenses for a user
 *
 * @param uid - The user ID
 *
 * @returns A promise that resolves to an array of licenses
 * @since 1.0.0
 */
const getLicenses = async (uid: string): Promise<License[]> => {
  const licensesRef: CollectionReference<DocumentData> = collection(
    firestore,
    `users/${uid}/licenses`,
  );
  const licensesSnapshot = await getDocs(licensesRef);
  const licenses: License[] = licensesSnapshot.docs.map((doc) =>
    License.fromMap({
      ...doc.data(),
      id: doc.id,
    }),
  );
  return licenses;
};

/**
 * Get a license by ID
 *
 * @param uid - The user ID
 * @param licenseId - The license ID
 *
 * @returns A promise that resolves to a License or null if the license does not exist
 * @since 1.0.0
 */
const getLicense = async (
  uid: string,
  licenseId: string,
): Promise<License | null> => {
  const licenseRef = doc(firestore, `users/${uid}/licenses`, licenseId);
  const licenseDoc = await getDoc(licenseRef);
  if (licenseDoc.exists()) {
    return License.fromMap(licenseDoc.data());
  }
  return null;
};

/**
 * Add a license
 *
 * @param uid - The user ID
 * @param license - The license to add
 *
 * @returns A promise that resolves when the license is added
 * @since 1.0.0
 */
const addLicense = async (uid: string, license: License): Promise<string> => {
  const licenseRef: CollectionReference<DocumentData> = collection(
    firestore,
    `users/${uid}/licenses`,
  );
  license.created_at = serverTimestamp();
  license.updated_at = serverTimestamp();
  const docRef = await addDoc(licenseRef, license.toMap());
  return docRef.id;
};

/**
 * Update a license
 *
 * @param uid - The user ID
 * @param licenseId - The license ID
 *
 * @returns A promise that resolves when the license is updated
 * @since 1.0.0
 */
const updateLicense = async (
  uid: string,
  licenseId: string,
  license: License,
): Promise<void> => {
  const licenseRef = doc(firestore, `users/${uid}/licenses`, licenseId);
  await setDoc(licenseRef, license.toMap());
};

export { getLicenses, getLicense, addLicense, updateLicense };
