"use client";

import React, { useState, useEffect, Suspense } from "react";
import ProtectedLayout from "../components/layouts/ProtectedLayout";
import { ChevronDown, ChevronUp, Plus } from "lucide-react";
import ButtonComp from "../components/ButtonComp";
import TabComp from "../components/TabComp";
import AccountTab from "../Tabs/Settings/AccountTab";
import BillingTab from "../Tabs/Settings/BillingTab";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";
import { TabItem } from "../types/dashboard";

const tabs = [
  { id: "account", label: "Account", component: AccountTab },
  { id: "billing", label: "Billings", component: BillingTab },
];

const Settings = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(
    searchParams.get("tab") || "account",
  );

  useEffect(() => {
    const tabFromUrl = searchParams.get("tab");
    if (tabFromUrl && tabs.some((tab) => tab.id === tabFromUrl)) {
      setActiveTab(tabFromUrl);
    }
  }, [searchParams]);

  const handleTabChange = (tabId: string) => {
    setActiveTab(tabId);
    setSearchParams({ tab: tabId });
  };

  const ActiveTabComponent = tabs.find(
    (tab) => tab.id === activeTab,
  )?.component;

  return (
    <ProtectedLayout>
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-white">Settings</h1>
      </div>
      <div className="space-y-4">
        <div className="bg-[#0A0A0A] rounded-lg shadow p-4 border-t border-gray-700">
          <TabComp
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={handleTabChange}
          >
            {ActiveTabComponent && <ActiveTabComponent />}
          </TabComp>
        </div>
      </div>
    </ProtectedLayout>
  );
};

const SettingsPage = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <Settings />
  </Suspense>
);

export default SettingsPage;
