import React from "react";
import InputComp from "../../components/InputComp";
import ButtonComp from "../../components/ButtonComp";
import { useLicenseStore } from "../../store/licenseStore";
import { updateLicense as updateLicenseInFirebase } from "../../lib/license";
import { useUserStore } from "../../store/userStore";
import { serverTimestamp } from "firebase/firestore";
const AccountTab = (brand_id = "") => {
  const { user } = useUserStore();
  const [loading, setLoading] = React.useState(false);
  const { selectedLicense, updateLicense } = useLicenseStore();
  const [brandName, setBrandName] = React.useState(
    selectedLicense?.brand_name || "",
  );
  const [previousName, setPreviousName] = React.useState(
    selectedLicense?.brand_name || "",
  );

  React.useEffect(() => {
    if (selectedLicense) {
      selectedLicense!.brand_name = brandName || "";
      selectedLicense!.leaflink_api = selectedLicense!.leaflink_api || "";
      selectedLicense!.webhook = selectedLicense!.webhook || "";
      selectedLicense!.hubspot_access_token =
        selectedLicense!.hubspot_access_token || "";
      selectedLicense!.hubspot_refresh_token =
        selectedLicense!.hubspot_refresh_token || "";
      selectedLicense!.hubspot_expires_in =
        selectedLicense!.hubspot_expires_in || "";
      selectedLicense!.updated_at =
        selectedLicense!.updated_at || serverTimestamp();
      selectedLicense!.created_at =
        selectedLicense!.created_at || serverTimestamp();
      updateLicense(selectedLicense!);
      handleUpdate();
    }
  }, [brandName]);

  const handleUpdate = async () => {
    setLoading(true);
    try {
      await updateLicenseInFirebase(
        user!.uid,
        selectedLicense!.id,
        selectedLicense!,
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDiscard = () => {
    setBrandName(previousName);
  };

  return (
    <div className="text-white">
      <div className="mb-4">
        <label className="block text-sm font-medium mb-1">Account name</label>
        <p className="text-sm text-gray-400 mb-2">
          Name to be used for this account.
        </p>
        <InputComp
          value={brandName || ""}
          onChange={(e) => setBrandName(e.target.value)}
        />
      </div>
      {/* <div className='flex justify-end space-x-4 mt-4'>
        <ButtonComp variant='danger' onClick={handleDiscard}>Discard Changes</ButtonComp>
        <ButtonComp loading={loading} onClick={handleUpdate}>
          {loading ? 'Updating...' : 'Update Account'}
        </ButtonComp>
      </div> */}
    </div>
  );
};

export default AccountTab;
