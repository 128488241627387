import React, { useState, useEffect, Suspense } from "react";
import { useNavigate, useLocation } from "react-router-dom"; // Import useLocation for query parameters
import ProtectedLayout from "../components/layouts/ProtectedLayout";
import { ChevronDown, ChevronUp, Plus } from "lucide-react";
import TabComp from "../components/TabComp";
import ButtonComp from "../components/ButtonComp";
import AccountTab from "../Tabs/Dashboard/AccountTab";
import IntegrationsTab from "../Tabs/Dashboard/IntegrationsTab";
import MappingTab from "../Tabs/Dashboard/MappingTab";
import { Brand, TabItem } from "../types/dashboard";
import License from "../modals/License";
import {
  getLicenses,
  addLicense,
  updateLicense as updateLicenseInFirebase,
} from "../lib/license";
import { useUserStore } from "../store/userStore";
import { useLicenseStore } from "../store/licenseStore";
import Spinner from "../components/Spinner";
import EmptyScreenComp from "../components/EmptyScreenCom";
import { CircleDashed, Clock } from "lucide-react";
let alerted = false;

const tabs: TabItem[] = [
  { id: "account", label: "Account", component: AccountTab },
  { id: "integrations", label: "Integrations", component: IntegrationsTab },
  { id: "mapping", label: "Mapping", component: MappingTab },
];

const Dashboard: React.FC = () => {
  const { user } = useUserStore();
  const {
    licenses,
    setLicenses,
    selectedLicense,
    setSelectedLicense,
    updateLicense,
  } = useLicenseStore();
  const [loader, setLoader] = useState(true);
  const [activeTab, setActiveTab] = useState<string>("account");
  const location = useLocation();

  // Function to handle query parameters
  const checkQueryParams = () => {
    const searchParams = new URLSearchParams(location.search);

    // Show toast if success or error
    if (searchParams.get("success") === "hubspot") {
      if (!alerted) {
        alert("HubSpot connection was made successfully.");
        alerted = true;
      }
    } else if (searchParams.get("error") === "hubspot") {
      if (!alerted) {
        alert("Error connecting to HubSpot.");
        alerted = true;
      }
    }
  };

  useEffect(() => {
    checkQueryParams(); // Call on mount to check for query params
    _getLicenses();
  }, [user, location.search]); // Re-run when URL query changes

  const toggleExpanded = (id: string) => {
    const license = licenses.find((license) => license.id === id.toString());

    if (license?.id === selectedLicense?.id) {
      setSelectedLicense(null);
    } else {
      setSelectedLicense(license!);
    }
  };

  const handleTabChange = (tabId: string) => {
    setActiveTab(tabId);
  };

  const ActiveTabComponent = tabs.find(
    (tab) => tab.id === activeTab,
  )?.component;

  const _getLicenses = async () => {
    try {
      const licenses: License[] = await getLicenses(user!?.uid);
      if (licenses.length > 0) {
        setLicenses(licenses);
        console.log("licenses", licenses);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  const addLicenseHandler = async () => {
    try {
      setLoader(true);
      const license = new License({
        id: "",
        brand_name: "Brand Name",
        leaflink_api: "",
        webhook: "",
        hubspot_access_token: "",
        hubspot_refresh_token: "",
        hubspot_expires_in: "",
      });
      let id = await addLicense(user!?.uid!, license);
      license.id = id;
      license.webhook = `https://webhook.mjsync.io/webhook/${user?.uid}/${id}`;
      await updateLicenseInFirebase(user!?.uid!, id, license);
    } catch (error) {
      console.error(error);
    } finally {
      _getLicenses();
    }
  };

  return (
    <ProtectedLayout>
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-white">
          Leaflink To HubSpot Sync.
        </h1>
        <ButtonComp className="flex" onClick={addLicenseHandler}>
          Add New License <Plus className="hidden md:block ml-2" />
        </ButtonComp>
      </div>

      {loader && <Spinner />}
      {!loader && licenses.length === 0 && (
        <EmptyScreenComp
          title="No Licenses Found"
          description="No licenses found. Click on Add New License to add a new license."
        />
      )}
      {!loader && (
        <div className="space-y-4">
          {licenses.map((license: License, index: number) => (
            <div key={index} className="bg-[#0A0A0A] rounded-lg shadow">
              <button
                className="w-full flex justify-between items-center p-4 text-white"
                onClick={() => toggleExpanded(license.id)}
              >
                <span className="font-semibold">{license.brand_name}</span>

                <div className="flex items-center space-x-4">
                  <div className="flex items-center">
                    <CircleDashed size={15} className="text-green-500" />
                    <span className="ml-2 text-green-500">Connected</span>
                  </div>
                  <div className="flex items-center">
                    <Clock size={15} className="text-gray-500" />
                    <span className="ml-2 text-gray-500">
                      Last Synced 20 minutes ago
                    </span>
                  </div>
                  {license.id === selectedLicense?.id ? (
                    <ChevronUp size={20} />
                  ) : (
                    <ChevronDown size={20} />
                  )}
                </div>
              </button>
              {license.id === selectedLicense?.id && (
                <div className="p-4 border-t border-gray-700">
                  <TabComp
                    tabs={tabs}
                    activeTab={activeTab}
                    setActiveTab={handleTabChange}
                  >
                    {ActiveTabComponent && (
                      <ActiveTabComponent brand_id={license.brand_name} />
                    )}
                  </TabComp>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </ProtectedLayout>
  );
};

const DashboardPage = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Dashboard />
    </Suspense>
  );
};

export default DashboardPage;
