import React from "react";

interface TabProps {
  tabs: { id: string; label: string }[];
  activeTab: string;
  setActiveTab: (id: string) => void;
  children: React.ReactNode;
}

const TabComp: React.FC<TabProps> = ({
  tabs,
  activeTab,
  setActiveTab,
  children,
}) => {
  return (
    <div>
      <div className="flex flex-wrap space-x-4 mb-4">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            className={`text-sm font-medium px-3 py-2 rounded-md transition-colors ${
              activeTab === tab.id
                ? "bg-[#46464D] text-white"
                : "text-gray-400 hover:text-white"
            }`}
            onClick={() => setActiveTab(tab.id)}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div>{children}</div>
    </div>
  );
};

export default TabComp;
