import React from "react";
import BaseLayout from "../components/layouts/BaseLayout";
import { mjSyncFeatures, leaflinkHubspotFeatures } from "../consts/features";

const Home: React.FC = () => {
  return (
    <BaseLayout iconOnly={false}>
      {/* Hero Section */}
      <section className="bg-gradient-to-r from-blue-600 to-indigo-700 py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h1 className="text-4xl sm:text-6xl font-bold text-white mb-6">
            We solve data sync issues
          </h1>
          <p className="text-xl text-gray-200 mb-8">
            By building amazing integrations that solve real-world problems.
          </p>
          <a
            href="#"
            className="bg-white text-blue-600 px-8 py-3 rounded-full font-bold text-lg hover:bg-gray-100 transition duration-300"
          >
            Get Started
          </a>
        </div>
      </section>

      {/* Our Apps Section */}
      <section className="py-20 bg-gray-800">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl sm:text-4xl font-bold text-center mb-12 text-white">
            Our Apps
          </h2>

          {/* Leaflink to HubSpot - Full Width */}
          <div className="bg-gray-700 rounded-lg shadow-lg p-8 mb-12">
            <div className="flex flex-col md:flex-row gap-8">
              {/* Left Column */}
              <div className="flex-1">
                <div className="flex items-center justify-center md:justify-start mb-6">
                  <img
                    src="/images/leaflink-logo.jpg"
                    alt="Leaflink logo"
                    width="80"
                    height="80"
                    className="mr-4"
                  />
                  <img
                    src="/images/hubspot-logo.webp"
                    alt="HubSpot logo"
                    width="80"
                    height="80"
                    className="ml-4"
                  />
                </div>
                <h3 className="text-2xl font-semibold mb-4 text-center md:text-left text-white">
                  Leaflink to HubSpot
                </h3>
                <p className="text-gray-300 mb-6 text-center md:text-left">
                  Seamlessly sync your Leaflink and HubSpot data for enhanced
                  productivity and streamlined operations.
                </p>
              </div>
              {/* Right Column */}
              <div className="flex-1">
                <h4 className="text-xl font-semibold mb-4 text-white">
                  Key Benefits
                </h4>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-8">
                  {leaflinkHubspotFeatures.map((feature, index) => (
                    <div
                      key={index}
                      className="flex items-center text-gray-300"
                    >
                      <span className="text-2xl mr-2">{feature.icon}</span>
                      <span>{feature.text}</span>
                    </div>
                  ))}
                </div>
                <div className="text-center md:text-left">
                  <a
                    href="#"
                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded-full transition duration-300"
                  >
                    Sign Up Now
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Other Integrations - Three Columns */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {[
              {
                name: "Leaflink to Quickbooks",
                logo1: "/images/leaflink-logo.jpg",
                logo2: "/images/quickbooks-logo.png",
              },
              {
                name: "Leaflink to PowerBI",
                logo1: "/images/leaflink-logo.jpg",
                logo2: "/images/powerbi-logo.png",
              },
              {
                name: "Leaflink to Klaviyo",
                logo1: "/images/leaflink-logo.jpg",
                logo2: "/images/klaviyo-logo.svg",
              },
            ].map((app, index) => (
              <div
                key={index}
                className="bg-gray-700 p-6 rounded-lg shadow-lg flex flex-col items-center"
              >
                <div className="flex items-center justify-center mb-4">
                  <img
                    src={app.logo1}
                    alt="Leaflink logo"
                    width="60"
                    height="60"
                    className="mr-2"
                  />
                  <img
                    src={app.logo2}
                    alt={`${app.name.split(" to ")[1]} logo`}
                    width="60"
                    height="60"
                    className="ml-2"
                  />
                </div>
                <h3 className="text-xl font-semibold mb-2 text-white">
                  {app.name}
                </h3>
                <p className="mb-4 text-center text-gray-300">Coming Soon</p>
                <a
                  href="#"
                  className="text-blue-400 hover:underline flex items-center"
                >
                  Notify Me
                </a>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Benefits Section */}
      <section className="py-20 bg-gray-900">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl sm:text-4xl font-bold text-center mb-12 text-white">
            Benefits
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {mjSyncFeatures.map((benefit, index) => (
              <div key={index} className="bg-gray-800 p-6 rounded-lg shadow-lg">
                <div className="text-4xl mb-4 text-blue-500">
                  {benefit.icon}
                </div>
                <h3 className="text-xl font-semibold mb-2 text-white">
                  {benefit.title}
                </h3>
                <p className="text-gray-300">
                  Brief description of how this benefit helps users.
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Stay Informed Section */}
      <section className="py-20 bg-blue-600">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl sm:text-4xl font-bold mb-4 text-white">
            Stay Informed
          </h2>
          <p className="text-xl mb-8 text-gray-200">
            Get access to the latest news and information about our integrations
            and updates.
          </p>
          <form className="flex flex-col sm:flex-row gap-4 justify-center">
            <input
              type="email"
              placeholder="Enter your email"
              className="flex-grow px-4 py-2 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-white"
              required
            />
            <button
              type="submit"
              className="bg-white text-blue-600 font-bold py-2 px-6 rounded-full hover:bg-gray-100 transition duration-300"
            >
              Subscribe
            </button>
          </form>
          <p className="text-sm mt-4 text-gray-200">
            We respect your privacy. Unsubscribe at any time.
          </p>
        </div>
      </section>
    </BaseLayout>
  );
};

export default Home;
