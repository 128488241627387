import React from "react";
import InputComp from "../../components/InputComp";
import ButtonComp from "../../components/ButtonComp";
import { useLicenseStore } from "../../store/licenseStore";
import { useUserStore } from "../../store/userStore";
import {
  retrieveLeafLinkKey,
  storeLeafLinkKey,
  storeLeafLinkWebhook,
} from "../../services/api/leaflink";
import { updateLicense as updateLicenseInFirebase } from "../../lib/license";
import { serverTimestamp } from "firebase/firestore";
import { get } from "http";

const IntegrationsTab = (brand_id = "") => {
  const { user } = useUserStore();
  const [loading, setLoading] = React.useState(false);
  const { selectedLicense } = useLicenseStore();
  const [leafLinkApiKey, setLeafLinkApiKey] = React.useState("");
  const [webhook, setWebhook] = React.useState("");

  // Get the Leaflink API key for the current user
  React.useEffect(() => {
    getLeaflinkKey();
    getWebhook();
  }, [selectedLicense]);

  const updateLeaflinKey = async (lfkey: string) => {
    setLoading(true);
    try {
      if (!user || !user.uid) {
        console.error("User is not logged in");
        return;
      }
      if (!selectedLicense) {
        console.error("No license selected");
        return;
      }
      console.log(
        "Updating license for user ID:" +
          user.uid +
          " and license ID:" +
          selectedLicense.id +
          " with leaflink API key:" +
          lfkey,
      );
      selectedLicense.leaflink_api = lfkey;
      selectedLicense.updated_at = serverTimestamp();
      updateLicenseInFirebase(
        user!.uid, // Make sure the user is logged in
        selectedLicense!.id,
        selectedLicense!,
      );
      setLoading(false);
    } catch (error) {
      console.error("Error updating license:", error);
    }
  };

  const updateLeaflinWebhook = async (wh: string) => {
    setLoading(true);
    try {
      if (!user || !user.uid) {
        console.error("User is not logged in");
        return;
      }
      if (!selectedLicense) {
        console.error("No license selected");
        return;
      }
      console.log(
        "Updating license for user ID:" +
          user.uid +
          " and license ID:" +
          selectedLicense.id +
          " with webhook:" +
          wh,
      );
      selectedLicense.webhook = wh;
      selectedLicense.updated_at = serverTimestamp();
      updateLicenseInFirebase(
        user!.uid, // Make sure the user is logged in
        selectedLicense!.id,
        selectedLicense!,
      );
      setLoading(false);
    } catch (error) {
      console.error("Error updating license:", error);
    }
  };
  const getLeaflinkKey = async () => {
    if (!user || !user.uid) {
      console.error("User is not logged in");
      return;
    }
    const lfkey = selectedLicense?.leaflink_api || "";
    setLeafLinkApiKey(lfkey);
  };

  const getWebhook = async () => {
    if (!user || !user.uid) {
      console.error("User is not logged in");
      return;
    }
    const wh = selectedLicense?.webhook || "";
    setWebhook(wh);
  };

  const handleHubSpotConnect = () => {
    if (!user || !user.uid) {
      console.error("User is not logged in");
      return;
    }

    // Construct the HubSpot authorization URL with state=USERID
    const authorizationUrl = `https://app.hubspot.com/oauth/authorize?client_id=1600955c-8782-4703-bb6b-f71722eb40bc&redirect_uri=https://us-central1-mjsync-b7cc9.cloudfunctions.net/hubspotOAuthCallback&scope=crm.schemas.companies.write%20crm.schemas.contacts.write%20crm.objects.line_items.read%20crm.schemas.deals.read%20crm.objects.line_items.write%20crm.schemas.deals.write%20crm.schemas.line_items.read%20crm.pipelines.orders.write%20crm.dealsplits.read_write%20crm.pipelines.orders.read%20oauth%20crm.objects.contacts.write%20crm.objects.companies.write%20crm.objects.companies.read%20crm.objects.deals.read%20crm.schemas.contacts.read%20crm.objects.deals.write%20crm.objects.contacts.read%20crm.schemas.companies.read&state=${selectedLicense?.id}/${user.uid}`;

    // Redirect the user to HubSpot OAuth
    window.location.href = authorizationUrl;
  };

  return (
    <div className="text-white">
      <section>
        <div>
          <h4 className="text-md font-semibold mb-1">Leaflink API</h4>
          <p className="text-gray-400">Setup your leaflink API below</p>
          <InputComp
            placeholder="Enter API Key"
            value={leafLinkApiKey}
            onChange={(e) => (
              console.log("LeafLink API Key:", e.target.value),
              setLeafLinkApiKey(e.target.value),
              setTimeout(() => updateLeaflinKey(e.target.value), 1000)
            )}
          />
        </div>
        <div className="mt-4">
          <h4 className="text-md font-semibold mb-1">Webhook</h4>
          <p className="text-gray-400">
            Create a new Webhook to the URL <br></br>
            <br></br>
            https://us-central1-mjsync-b7cc9.cloudfunctions.net/webhook/
            {user?.uid}/ <br></br>
            <br></br>then paste the Webhook API below
          </p>
          <InputComp
            value={webhook}
            readOnly={false}
            onChange={(e) => (
              setWebhook(e.target.value),
              setTimeout(() => updateLeaflinWebhook(e.target.value), 1000)
            )}
          />
        </div>
      </section>
      {selectedLicense?.hubspot_access_token === "" && (
        <div className="mt-4">
          <h4 className="text-md font-semibold mb-1">HubSpot Authentication</h4>
          <p className="text-gray-400">
            Sign-into HubSpot to authorize application sync.
          </p>
          <div className="mt-4 flex justify-left">
            <ButtonComp variant="secondary" onClick={handleHubSpotConnect}>
              Connect HubSpot
            </ButtonComp>
          </div>
        </div>
      )}
    </div>
  );
};

export default IntegrationsTab;
