"use client";

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Menu,
  X,
  User,
  ChevronUp,
  ChevronDown,
  Settings,
  FolderSync,
} from "lucide-react";
import { ProtectedLayoutType } from "../../types/layouts";
import { useAuth } from "../../context/auth";
import {
  User as FirebaseUser,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import { auth } from "../../services/firebase";
import { useUserStore } from "../../store/userStore";

const ProtectedLayout: React.FC<ProtectedLayoutType> = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);
  const [authChecked, setAuthChecked] = useState(false);
  const { name, updateUserName, user, setUser } = useUserStore();

  const currentPath = useLocation();
  const navigate = useNavigate();

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
  const toggleUserDropdown = () => setIsUserDropdownOpen(!isUserDropdownOpen);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      updateUserName(user?.displayName || user?.email || "");
      setAuthChecked(true);
      if (!user) {
        navigate("/login");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        console.log("User signed out");
        navigate("/login");
      })
      .catch((error) => {
        console.error("Error signing out:", error);
      });
  };

  if (!user) {
    return null;
  }

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Sidebar */}
      <div
        className={`bg-[#0A0A0A] text-white w-64 flex flex-col justify-between py-7 px-2 absolute inset-y-0 left-0 transform ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"} md:relative md:translate-x-0 transition duration-200 ease-in-out`}
      >
        <nav>
          <img
            src="/images/mysync-logo-white.png"
            className="-mt-6 w-36 mx-auto"
            alt="MySync Logo"
          />
          <Link
            to="/dashboard?tab=account&brand_id=1"
            className={`flex py-2.5 px-4 rounded transition duration-200 hover:bg-[#46464D] ${currentPath.pathname === "/dashboard" ? "bg-[#46464D]" : ""}`}
          >
            <FolderSync /> <span className="ml-2">Leaflink to HubSpot</span>
          </Link>
          <Link
            to="/settings?tab=account"
            className={`flex py-2.5 px-4 rounded transition duration-200 hover:bg-[#46464D] ${currentPath.pathname === "/settings" ? "bg-[#46464D]" : ""}`}
          >
            <Settings /> <span className="ml-2">Settings</span>
          </Link>
        </nav>

        {/* User section */}
        <div className="relative">
          <button
            onClick={toggleUserDropdown}
            className="flex items-center w-full py-2.5 px-4 rounded transition duration-200 hover:bg-[#46464D]"
          >
            <User size={20} className="mr-2" />
            <span>{name}</span>
            {isUserDropdownOpen ? (
              <ChevronUp size={20} className="ml-auto" />
            ) : (
              <ChevronDown size={20} className="ml-auto" />
            )}
          </button>
          {isUserDropdownOpen && (
            <div className="absolute bottom-full left-0 w-full rounded-t-md overflow-hidden">
              <button
                className="w-full text-left py-2 px-4 hover:bg-[#46464D]"
                onClick={handleLogout}
              >
                Logout
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Main content */}
      <div className="flex-1 flex flex-col overflow-hidden">
        {/* Top bar */}
        <header className="bg-[#0A0A0A] shadow-md">
          <div className="flex items-center justify-between p-4">
            <h1 className="text-xl font-semibold">
              Welcome {name?.split(" ")[0]}!
            </h1>
            <button
              onClick={toggleSidebar}
              className="text-gray-500 focus:outline-none focus:text-gray-700 md:hidden"
            >
              {isSidebarOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </header>

        {/* Page content */}
        <main className="bg-[#0A0A0A]">
          <section className="flex-1 overflow-x-hidden overflow-y-auto bg-[#373839] h-screen p-6 rounded-lg">
            {children}
          </section>
        </main>
      </div>
    </div>
  );
};

export default ProtectedLayout;
