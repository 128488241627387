import React from "react";
import { BaseLayoutType } from "../../types/layouts";
import { Link } from "react-router-dom";

const BaseLayout: React.FC<BaseLayoutType> = ({ iconOnly, children }) => {
  return (
    <div
      className="flex flex-col min-h-screen"
      style={{ backgroundColor: "#191A1A", color: "white" }}
    >
      <nav className="shadow-lg" style={{ backgroundColor: "#222324" }}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center h-16">
            <div className="flex items-center">
              <Link to="/">
                <img
                  src="/images/mysync-logo-white.png"
                  alt="MJSync Logo"
                  className="w-36"
                />
              </Link>
            </div>
            {!iconOnly && (
              <>
                <div className="hidden sm:flex space-x-4">
                  <a
                    href="#"
                    className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                  >
                    Our Apps
                  </a>
                  <a
                    href="#"
                    className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                  >
                    Benefits
                  </a>
                  <a
                    href="#"
                    className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                  >
                    Contact
                  </a>
                </div>
                <Link
                  to="/login"
                  className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-full transition duration-300"
                >
                  Login
                </Link>
              </>
            )}
          </div>
        </div>
      </nav>
      <main className="flex-grow">{children}</main>
      <footer className="py-8" style={{ backgroundColor: "#222324" }}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col sm:flex-row justify-between items-center">
            <div className="flex space-x-6 mb-4 sm:mb-0">
              <a href="#" className="text-gray-300 hover:text-white">
                Terms
              </a>
              <a href="#" className="text-gray-300 hover:text-white">
                Privacy
              </a>
              <a href="#" className="text-gray-300 hover:text-white">
                Contact Us
              </a>
            </div>
            <div className="text-gray-300">
              © 2024 Your Company Name. All rights reserved.
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default BaseLayout;
