import { create } from "zustand";
import { User as FirebaseUser } from "firebase/auth";

interface UserState {
  user: FirebaseUser | null;
  name: string | null;
  setUser: (user: FirebaseUser | null) => void;
  updateUserName: (name: string) => void;
}

export const useUserStore = create<UserState>((set) => ({
  user: null,
  name: null,
  setUser: (user) => set({ user }),

  updateUserName: (name) => {
    set({ name });
  },
}));
