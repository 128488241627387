"use client";

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../components/Spinner";
import InputComp from "../components/InputComp";
import ButtonComp from "../components/ButtonComp";
import AuthLayout from "../components/layouts/AuthLayout";
import { auth } from "../services/firebase";
import { sendPasswordResetEmail } from "firebase/auth";

export default function PasswordReset() {
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      toast.success(
        "If an account exists, an email has been sent to reset your password.",
      );
      navigate("/login");
    } catch (error) {
      toast.error("Failed to send password reset email");
    }
  };

  return (
    <AuthLayout title="Reset your password">
      <div className="flex flex-col gap-4" role="form">
        <InputComp
          placeholder="Enter your Email"
          value={email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setEmail(e.target.value)
          }
        />
        <ButtonComp loading={loader} type="submit">
          {loader ? "Resetting..." : "Reset Password"}
        </ButtonComp>
      </div>

      {error && <div className="text-center text-red-500">{error}</div>}
    </AuthLayout>
  );
}
