import { FieldValue } from "firebase/firestore";

class License {
  brand_name: string;
  leaflink_api?: string;
  webhook?: string;
  hubspot_access_token: string;
  hubspot_refresh_token: string;
  hubspot_expires_in: string;
  id: string;
  created_at?: FieldValue;
  updated_at?: FieldValue;

  constructor({
    brand_name,
    leaflink_api,
    webhook,
    hubspot_access_token,
    hubspot_refresh_token,
    hubspot_expires_in,
    id,
    created_at,
    updated_at,
  }: {
    brand_name: string;
    leaflink_api: string;
    webhook: string;
    hubspot_access_token: string;
    hubspot_refresh_token: string;
    hubspot_expires_in: string;
    id: string;
    created_at?: FieldValue;
    updated_at?: FieldValue;
  }) {
    this.brand_name = brand_name;
    this.leaflink_api = leaflink_api;
    this.webhook = webhook;
    this.hubspot_access_token = hubspot_access_token;
    this.hubspot_refresh_token = hubspot_refresh_token;
    this.hubspot_expires_in = hubspot_expires_in;
    this.id = id;
    this.created_at = created_at;
    this.updated_at = updated_at;
  }

  static fromMap(json: { [key: string]: any }): License {
    return new License({
      brand_name: json.brand_name,
      leaflink_api: json.leaflink_api,
      webhook: json.webhook,
      hubspot_access_token: json.hubspot_access_token,
      hubspot_refresh_token: json.hubspot_refresh_token,
      hubspot_expires_in: json.hubspot_expires_in,
      id: json.id,
      created_at: json.created_at,
      updated_at: json.updated_at,
    });
  }

  toMap(): { [key: string]: any } {
    return {
      brand_name: this.brand_name,
      leaflink_api: this.leaflink_api,
      webhook: this.webhook,
      hubspot_access_token: this.hubspot_access_token,
      hubspot_refresh_token: this.hubspot_refresh_token,
      hubspot_expires_in: this.hubspot_expires_in,
      id: this.id,
      created_at: this.created_at,
      updated_at: this.updated_at,
    };
  }
}

export default License;
