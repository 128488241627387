import React, { useState, useRef, useEffect } from "react";
import { ChevronDown, X } from "lucide-react";

interface SelectProps {
  label?: string;
  options: { value: string; label: string }[];
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
}

const SelectComp: React.FC<SelectProps> = ({
  label,
  options,
  value,
  onChange,
  disabled = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      // @ts-expect-error
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (value) {
      const selectedOption = options.find((option) => option.value === value);
      setInputValue(selectedOption ? selectedOption.label : value);
    }
  }, [value, options]);

  const handleInputChange = (e: any) => {
    if (disabled) return;
    const input = e.target.value;
    setInputValue(input);
    setIsOpen(true);
    setFilteredOptions(
      options.filter((option) =>
        option.label.toLowerCase().includes(input.toLowerCase()),
      ),
    );
  };

  const handleOptionClick = (option: any) => {
    if (disabled) return;
    onChange(option.value);
    setInputValue(option.label);
    setIsOpen(false);
  };

  const handleKeyDown = (e: any) => {
    if (disabled) return;
    if (e.key === "Enter" && inputValue && !filteredOptions.length) {
      onChange(inputValue);
      setIsOpen(false);
    }
  };

  const clearSelection = () => {
    onChange("");
    setInputValue("");
    setIsOpen(false);
  };

  return (
    <div className="relative" ref={dropdownRef}>
      {label && (
        <label className="block text-sm font-medium text-white opacity-70 mb-1">
          {label}
        </label>
      )}
      <div className="relative">
        <input
          ref={inputRef}
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onFocus={() => setIsOpen(true)}
          onKeyDown={handleKeyDown}
          className="block !bg-[#373839] w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
          placeholder="Select or type..."
          disabled={disabled}
        />
        <div className="absolute inset-y-0 right-0 flex items-center pr-2">
          {value && (
            <button
              onClick={clearSelection}
              className="p-1 hover:bg-blue-500 rounded-full"
            >
              <X className="h-4 w-4 text-white" />
            </button>
          )}
          <ChevronDown
            className={`h-5 w-5 text-gray-400 transition-transform ${
              isOpen ? "transform rotate-180" : ""
            }`}
          />
        </div>
      </div>
      {isOpen && (
        <ul className="absolute !bg-[#373839] z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
          {filteredOptions.map((option) => (
            <li
              key={option.value}
              onClick={() => handleOptionClick(option)}
              className="cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-blue-500"
            >
              {option.label}
            </li>
          ))}
          {!filteredOptions.length && (
            <li className="cursor-default select-none relative py-2 pl-3 pr-9 text-gray-300">
              No results found
            </li>
          )}
        </ul>
      )}
    </div>
  );
};

export default SelectComp;
