"use client";

import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../components/Spinner";
import InputComp from "../components/InputComp";
import ButtonComp from "../components/ButtonComp";
import AuthLayout from "../components/layouts/AuthLayout";
import { auth } from "../services/firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { updateUserProfile, getUserData } from "../lib/user";
import {
  User as FirebaseUser,
  signOut,
  sendEmailVerification,
  updateProfile,
} from "firebase/auth";
import { handleError } from "../utils/error";

export default function Login() {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setError("");
      setLoader(true);
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password,
      );
      const user: FirebaseUser = userCredential.user;
      await updateUserProfile(user, { name });
      await updateProfile(user, {
        displayName: name,
      });
      await sendEmailVerification(user);
      await getUserData(user);
      // signout
      await signOut(auth);
      toast.success("Please verify your email to continue");
      navigate("/login");
    } catch (error) {
      setError(await handleError(error));
    } finally {
      setLoader(false);
    }
  };

  return (
    <AuthLayout title="Sign Up">
      <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
        <InputComp
          type="text"
          placeholder="Enter your Name"
          value={name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setName(e.target.value)
          }
        />
        <InputComp
          type="email"
          placeholder="Enter your Email"
          value={email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setEmail(e.target.value)
          }
        />
        <InputComp
          type="password"
          placeholder="Enter your Password"
          value={password}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPassword(e.target.value)
          }
        />
        <div className="flex justify-between px-1 py-1">
          <Link
            className="text-center text-sm text-white hover:underline"
            to="/login"
          >
            Already have an account? Login
          </Link>
        </div>

        <ButtonComp loading={loader} type="submit">
          {loader ? "Signing up..." : "Sign Up"}
        </ButtonComp>
      </form>
      {error && <div className="text-center text-red-500">{error}</div>}
    </AuthLayout>
  );
}
