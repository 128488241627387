import React from "react";
import InputComp from "../../components/InputComp";
import ButtonComp from "../../components/ButtonComp";
import { Download } from "lucide-react";

const BillingTab = ({ brand_id = "" }) => {
  const [loading, setLoading] = React.useState(false);

  const currentPlan = {
    name: "Pro Plan",
    price: 49.99,
    billingCycle: "monthly",
    nextBillingDate: "2024-05-01",
  };

  const paymentMethod = {
    type: "Visa",
    last4: "4242",
    expiryDate: "12/2025",
  };

  const billingHistory = [
    { date: "2024-04-01", amount: 49.99, status: "Paid", invoice: "#INV-001" },
    { date: "2024-03-01", amount: 49.99, status: "Paid", invoice: "#INV-002" },
    { date: "2024-02-01", amount: 49.99, status: "Paid", invoice: "#INV-003" },
  ];

  const handleUpdatePaymentMethod = () => {
    setLoading(true);
    setTimeout(() => setLoading(false), 2000);
  };

  const handleUpgradePlan = () => {
    // TODO
  };

  return (
    <div className="text-white">
      <h2 className="text-2xl font-bold mb-6">Billing Management</h2>

      {/* Current Plan Section */}
      <div className="mb-8 bg-[#373839] p-6 rounded-lg">
        <h3 className="text-xl font-semibold mb-4">Current Plan</h3>
        <div className="flex justify-between items-center">
          <div>
            <p className="font-bold text-2xl">{currentPlan.name}</p>
            <p className="text-gray-400">
              ${currentPlan.price}/{currentPlan.billingCycle}
            </p>
            <p className="text-sm text-gray-400">
              Next billing date: {currentPlan.nextBillingDate}
            </p>
          </div>
          <ButtonComp onClick={handleUpgradePlan} variant="primary">
            Upgrade Plan
          </ButtonComp>
        </div>
      </div>

      {/* Billing History Section */}
      <div className="mb-8 bg-[#373839] p-6 rounded-lg">
        <h3 className="text-xl font-semibold mb-4">Billing History</h3>
        <table className="w-full">
          <thead>
            <tr className="border-b border-gray-700">
              <th className="text-left pb-2">Date</th>
              <th className="text-left pb-2">Amount</th>
              <th className="text-left pb-2">Status</th>
              <th className="text-left pb-2">Invoice</th>
            </tr>
          </thead>
          <tbody>
            {billingHistory.map((bill, index) => (
              <tr key={index} className="border-b border-gray-700">
                <td className="py-2">{bill.date}</td>
                <td className="py-2">${bill.amount}</td>
                <td className="py-2">{bill.status}</td>
                <td className="py-2">
                  <ButtonComp variant="secondary" size="small">
                    <Download className="inline-block mr-1" size={16} />{" "}
                    {bill.invoice}
                  </ButtonComp>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Cancel subscription */}
      <div className="flex justify-end space-x-4 mt-4">
        <ButtonComp variant="danger">Cancel Subscription</ButtonComp>
      </div>
    </div>
  );
};

export default BillingTab;
