import React, { useState } from "react";
import SelectComp from "./SelectComp";
import { ArrowRight, ArrowDown } from "lucide-react";

interface MapInputProps {
  id: string;
  leftOptions: { value: string; label: string }[];
  rightOptions: { value: string; label: string }[];
  onChange: (key: string, leftValue: string, rightValue: string) => void;
  disabled?: boolean;
}

const MapInputComp: React.FC<MapInputProps> = ({
  id,
  leftOptions,
  rightOptions,
  onChange,
  disabled = false,
}) => {
  const [leftValue, setLeftValue] = useState(leftOptions[0].value);
  const [rightValue, setRightValue] = useState(rightOptions[0].value);

  const handleLeftChange = (value: string) => {
    setLeftValue(value);
    onChange("left" + id, value, rightValue);
  };

  const handleRightChange = (value: string) => {
    setRightValue(value);
    onChange("right" + id, leftValue, value);
  };

  return (
    <div className="space-y-4">
      <div className="flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-4">
        {id !== "Deal" ? (
          <>
            <div className="w-full sm:flex-1">
              <SelectComp
                options={leftOptions}
                value={leftValue}
                onChange={handleLeftChange}
                disabled={disabled}
              />
            </div>
            <div className="flex items-center justify-center">
              <ArrowRight className="hidden sm:block h-6 w-6 text-gray-400" />
              <ArrowDown className="sm:hidden h-6 w-6 text-gray-400" />
            </div>
          </>
        ) : null}

        <div className="w-full sm:flex-1">
          <SelectComp
            options={rightOptions}
            value={rightValue}
            onChange={handleRightChange}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
};

export default MapInputComp;
