"use client";

import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../components/Spinner";
import InputComp from "../components/InputComp";
import ButtonComp from "../components/ButtonComp";
import AuthLayout from "../components/layouts/AuthLayout";
import { auth, firestore } from "../services/firebase";
import {
  User as FirebaseUser,
  signInWithEmailAndPassword,
  signInWithPopup,
  sendEmailVerification,
} from "firebase/auth";
import { googleProvider } from "../services/firebase";
import { updateUserProfile, getUserData } from "../lib/user";
import { handleError } from "../utils/error";

export default function Login() {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    setLoader(true);
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password,
      );
      const user: FirebaseUser = userCredential.user;
      if (!user.emailVerified) {
        // send verification email
        await sendEmailVerification(user);
        setError("Please verify your email, before logging in");
        await auth.signOut();
      }
      toast.success("Logged in successfully");
      navigate("/dashboard");
    } catch (error: any) {
      setError(await handleError(error));
    } finally {
      setLoader(false);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      setError("");
      setLoader(true);
      const userCredential = await signInWithPopup(auth, googleProvider);
      const user = userCredential.user;
      await updateUserProfile(user);
      toast.success("Logged in successfully");
      navigate("/dashboard");
    } catch (error: any) {
      setError(await handleError(error));
    } finally {
      setLoader(false);
    }
  };

  return (
    <AuthLayout title="Sign In">
      <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
        <InputComp
          placeholder="Enter your Email"
          value={email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setEmail(e.target.value)
          }
        />
        <InputComp
          type="password"
          placeholder="Enter your Password"
          value={password}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPassword(e.target.value)
          }
        />
        <div className="flex justify-between px-1 py-1">
          <Link
            className="text-center text-sm text-white hover:underline"
            to="/passwordreset"
          >
            Forgot password?
          </Link>
          <Link
            className="text-center text-sm text-white hover:underline"
            to="/signup"
          >
            Sign Up
          </Link>
        </div>
        <ButtonComp loading={loader} type="submit">
          {loader ? "signing in..." : "Sign In"}
        </ButtonComp>
        <ButtonComp
          loading={loader}
          onClick={handleGoogleSignIn}
          variant="white"
          className="flex items-center justify-center bg-white text-gray-700 hover:bg-gray-100 border border-gray-300"
        >
          {loader ? (
            "Signing in..."
          ) : (
            <>
              <img
                src="icons/google-icon.png"
                alt="Google Logo"
                className="w-6 h-6 mr-2"
              />
              Sign In with Google
            </>
          )}
        </ButtonComp>
      </form>
      {error && <div className="text-center text-red-500">{error}</div>}
    </AuthLayout>
  );
}
