import React from "react";
import InputComp from "../../components/InputComp";
import ButtonComp from "../../components/ButtonComp";
import { updateUserName as UpdateUserNameInProfile } from "../../lib/user";
import { useNavigate } from "react-router-dom";
import { useUserStore } from "../../store/userStore";

const AccountTab = () => {
  const { user, updateUserName } = useUserStore();

  const [loading, setLoading] = React.useState(false);
  const [name, setName] = React.useState(user?.displayName || "");
  const [email, setEmail] = React.useState(user?.email || "");
  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState("");

  const handleUpdate = async () => {
    setLoading(true);
    try {
      await UpdateUserNameInProfile(user!, name);
      setSuccess("User name updated successfully");
      updateUserName(name);
    } catch (error) {
      console.log(error);
      setError("Error updating user name");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="text-white">
      <div className="mb-4">
        <label className="block text-sm font-medium mb-1">Full Name</label>
        <p className="text-sm text-gray-400 mb-2">
          Name to be used for emails.
        </p>
        <InputComp
          value={name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setName(e.target.value)
          }
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium mb-1">Email</label>
        <p className="text-sm text-gray-400 mb-2">
          The email assosciated with your account.
        </p>
        <InputComp value={email} readOnly={true} onChange={() => {}} />
      </div>
      <div className="flex justify-end space-x-4 mt-4">
        <ButtonComp loading={loading} onClick={handleUpdate}>
          {loading ? "Updating..." : "Update"}
        </ButtonComp>
      </div>
      <div className="mt-4">
        {error && <p className="text-red-500">{error}</p>}
        {success && <p className="text-green-500">{success}</p>}
      </div>
    </div>
  );
};

export default AccountTab;
