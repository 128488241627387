import React from "react";
import { ToastContainer } from "react-toastify";
import BaseLayout from "./BaseLayout";

interface AuthLayoutType {
  title: string;
  children: React.ReactNode;
}

const AuthLayout: React.FC<AuthLayoutType> = ({ title, children }) => {
  return (
    <BaseLayout iconOnly={true}>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />

      <main className="flex-grow flex items-center justify-center ">
        <div className="flex w-full max-w-sm flex-col gap-4 rounded-lg bg-white/10 backdrop-blur-md px-8 pb-10 pt-6 mt-10 md:mt-40 shadow-lg border border-white/20 ">
          <div className="flex justify-center mb-4">
            <img
              src="./icons/mjsync-favicon-white.png"
              alt="MJSync Logo"
              className="w-16 h-16"
            />
          </div>
          <h2 className="pb-4 text-center text-white text-3xl font-semibold">
            {title}
          </h2>
          {children}
        </div>
      </main>
    </BaseLayout>
  );
};

export default AuthLayout;
