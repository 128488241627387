import React from "react";
import { Ban } from "lucide-react";

const EmptyScreenComp = ({
  title,
  description,
  buttonText,
  buttonAction,
}: any) => {
  return (
    <div className="flex flex-col rounded-lg justify-center items-center p-6 bg-[#0A0A0A]">
      <div className="flex justify-center w-full mb-4">
        <Ban />
      </div>
      <div className="text-center">
        <h5 className="text-white mb-2 text-2xl">{title}</h5>
        <p className="text-gray-400 text-lg mb-12 whitespace-pre-line">
          {description}
        </p>
      </div>
    </div>
  );
};

export default EmptyScreenComp;
