import { create } from "zustand";
import { User as FirebaseUser } from "firebase/auth";
import License from "../modals/License";

interface LicenseState {
  licenses: License[];
  selectedLicense?: License | null;
  setSelectedLicense: (license: License | null) => void;
  setLicenses: (licenses: License[]) => void;
  updateLicense: (license: License) => void;
}

export const useLicenseStore = create<LicenseState>((set) => ({
  licenses: [],
  selectedLicense: null,
  setSelectedLicense: (license: License | null) => {
    set((state) => ({
      selectedLicense: license,
    }));
  },
  setLicenses: (licenses: License[]) => {
    set((state) => ({
      licenses: licenses,
    }));
  },
  updateLicense: (license: License) => {
    // update it with id.
    set((state) => ({
      licenses: state.licenses.map((l) => (l.id === license.id ? license : l)),
    }));
  },
}));
