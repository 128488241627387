"use client";

import React, { createContext, useContext, useState, useEffect } from "react";
import { auth } from "../services/firebase";
import { User as FirebaseUser } from "firebase/auth";
const AuthContext = createContext<{ currentUser: any | null }>({
  currentUser: null,
});

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [currentUser, setCurrentUser] = useState<FirebaseUser | null>(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });

    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
