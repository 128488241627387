import React from "react";
import { Loader2 } from "lucide-react";
import ButtonType from "../types/button";

const ButtonComp: React.FC<ButtonType> = ({
  children,
  onClick,
  disabled = false,
  loading = false,
  type = "button",
  className = "",
  variant = "primary",
  size = "medium",
  fullWidth = false,
}) => {
  const baseClasses = "font-bold rounded focus:outline-none transition-colors";

  const variantClasses = {
    primary: "bg-[#1A7F65] text-white hover:bg-[#125e4b] focus:ring-[#125e4b]",
    secondary: "bg-[#363741] text-white hover:bg-gray-800 focus:ring-gray-500",
    danger: "bg-red-500 text-white hover:bg-red-600 focus:ring-red-500",
    white: "bg-white text-gray-700 hover:bg-gray-100 border border-gray-300",
  };

  const sizeClasses = {
    small: "px-2 py-1 text-sm",
    medium: "px-4 py-2",
    large: "px-6 py-3 text-lg",
  };

  const classes = `
    ${baseClasses}
    ${variantClasses[variant]}
    ${sizeClasses[size]}
    ${fullWidth ? "w-full" : ""}
    ${disabled || loading ? "opacity-50 cursor-not-allowed" : ""}
    ${className}
  `;

  return (
    <button
      type={type}
      className={classes}
      onClick={onClick}
      disabled={disabled || loading}
    >
      {loading ? (
        <span className="flex items-center justify-center">
          <Loader2 className="animate-spin mr-2 h-4 w-4" />
          {children}
        </span>
      ) : (
        children
      )}
    </button>
  );
};

export default ButtonComp;
